<template>
	<div class="tkEdit">
		<!-- 进度条 -->
		<yj-nav-detail>
			<div slot="contentSlot">
				<yj-progress-edit :statusList="statusList" :currentStatus="currentStatus"></yj-progress-edit>
			</div>
		</yj-nav-detail>
		
		<!-- 主页面 -->
		<div class="tkEdit-content flex justify-content-space-between">
			<!-- 左侧视图 -->
			<div class="tkEdit-left">
				<!-- 预订信息 -->
				<tk-cxr
				:isShowChooseZuo="isShowChooseZuo"
				:seatList="seatList"
				></tk-cxr>
				<!-- 差旅信息 -->
				<yj-travel-info
						v-if="this.tkQuery.tripType == 1"
						ref="tkTravelInfo"
						:cxrList="cxrList"
						:orderType="orderType"
						:clbzMap="clbzMap"
						:defaultPoject="project"
						:proType="10901"
						@chooseProject="chooseProject" 
						@getCxrList="getCxrList"
						@chooseSpgz="chooseSpgz"
						@selectReason="chooseReason"
						@changeWuXuShenPi="changeWuXuShenPi"
						></yj-travel-info>
					
				<!-- 政采信息 
				 
				 -->
				<zheng-cai-info 
					ref="tk-edit-zc" 
					v-if="tkQuery.tkType == 1 && tkQuery.tripType == 1"
					:bankName="tkEdit.bankName"
					:budgetUnit="tkEdit.budgetUnit"
					:gpValidType="tkEdit.gpValidType"
					@chooseBudgetUnit="chooseBudgetUnit"
					@chooseBank="chooseBank"
					@changeGpValidType="changeGpValidType"
					></zheng-cai-info>
				
				
				<!-- 联系人 -->
				<yj-linker
					:defaultLinker="linker"
					@getLinker="getLinker"
					></yj-linker>
				<!-- 保险 -->
				<yj-in-surance 
				 	v-if="!isHiddenBxList"
					:defaultBxList="defaultBxList" 
					cplx="10901" 
					:tripType="tkQuery.tripType"
					@chooseBxcp="chooseBxcp"
					></yj-in-surance>
				
				<!-- 南方航空预订 阅读条款 -->
				<div class="book-nh" v-if="nhShow && nhrh">
					<span @click="chooseReadNh">
						<span class="iconfont yj-icon-24gf-shield colornh-g" v-show="!isReadNh"></span>
						<span class="iconfont yj-icon-24gf-shieldCheck colornh" v-show="isReadNh"></span>
					</span>
					已阅读
					<el-button type="text" style="font-size: 14px;"  @click="lookTermNotice(1)">《南航会员条款》</el-button>及<el-button type="text"  style="font-size: 14px;" @click="lookTermNotice(2)">《隐私通知》</el-button>
					并同意提交订单后，若您非南航会员， 预订成功我们将自动为您注册南航会员
				</div>
				
				<!-- 阅读细则 -->
				<div class="book-rule">
					<span>点击下一步表示已阅读并同意 
						<el-button type="text" @click="lookBookBule()">《锂电池及危险品须知，携带及托运物品须知》</el-button>
					</span>
				</div>
				
				<!-- 提交操作 -->
				<div class="tk-button-list" v-show="!showTiJiaoAndChuBtn">
					<el-button class="ti-jiao-btn" type="primary" :loading="tiJiaoLoading" @click="nextStep()">提交订单</el-button>
					
				</div>
				<div class="tk-button-list" v-show="showTiJiaoAndChuBtn">
					<el-button class="ti-jiao-btn half-item first" type="primary" :loading="tiJiaoLoading&&this.ifAutoPay!=1" :disabled="tiJiaoLoading&&this.ifAutoPay==1" @click="nextStep()" v-show="showTiJiaoBtn">提交订单</el-button>
					<el-button class="ti-jiao-btn half-item" type="primary" :loading="tiJiaoLoading&&this.ifAutoPay==1" :disabled="tiJiaoLoading&&this.ifAutoPay!=1" @click="nextStep(1)">提交订单并出票</el-button>
				</div>
				
			</div>
			
			<!-- 右侧视图 航班信息 -->
			<div class="tkEdit-right" >
				<div class="tkEdit-right-content" :class="{'fixed':isFixedTkEditRight}">
					<tk-edit-flight-info
						:tkQuery="tkQuery"
						:chooseFlight="chooseFlight"
						:chooseFlightBack="chooseFlightBack"
						@changeFlight="changeFlight"
					>
						
					</tk-edit-flight-info>
					
					<tk-flight-price
						:chooseFlight="chooseFlight"
						:chooseFlightBack="chooseFlightBack"
						:cxrList="cxrList"
						:bxList="defaultBxList"
					>
						
					</tk-flight-price>
				</div>
				
			</div>
		</div>
		
		<!-- 底部 -->
		<div>
			
		</div>
		
		
		<!-- 预订须知弹窗 -->
		<el-dialog
			title="《锂电池及危险品须知，携带及托运物品须知》"
			:visible.sync="isShowBookRule"
			>
			<book-rule></book-rule>
		</el-dialog>
		<!-- 预订须知弹窗 -->
		<el-dialog
			:title="tnType==1?'《南航会员条款》':'《隐私通知》'"
			:visible.sync="isShowTeamNotice"
			>
			<terms-and-notices :tnType="tnType"></terms-and-notices>
		</el-dialog>
		
		<!-- 相同订单验证 -->
		<el-dialog
		  title="重复订单验证"
		  :visible.sync="showSameOrderTip"
		  width="50%"
		  >
		  <p style="text-align: left;">您可能存在以下重复订单：</p>
		  <el-table :data="sameTkOrderList">
			  <el-table-column property="orderNo" label="订单号"></el-table-column>
		      <el-table-column property="departTime" label="日期" ></el-table-column>
			  <el-table-column property="flightNo" label="航班号"></el-table-column>
			  <el-table-column property="departCityName" label="出发城市" ></el-table-column>
			  <!-- <el-table-column property="departAirportName" label="出发机场" ></el-table-column> -->
			  <el-table-column property="arriveCityName" label="到达城市" ></el-table-column>
			  <!-- <el-table-column property="arriveAirportName" label="到达机场" ></el-table-column> -->
			  
		      <el-table-column property="psgName" label="乘客" ></el-table-column>
		      
		    </el-table>
		  
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="cancelBookSameOrder()">取 消</el-button>
		    <el-button type="primary" @click="confirmBookSameOrder()">继续预订</el-button>
		  </span>
		</el-dialog>
		
	</div>
</template>

<script>
	import mixin from '@/mixin'
import { mapGetters, mapMutations, mapState } from 'vuex'
import BookRule from './tkComponent/tkEdit/BookRule.vue'
import TermsAndNotices from './tkComponent/tkEdit/TermsAndNotices.vue'
import TkCxr from './tkComponent/tkEdit/TkCxr.vue'
import TkEditFlightInfo from './tkComponent/tkEdit/TkEditFlightInfo.vue'
import TkFlightPrice from './tkComponent/tkEdit/TkFlightPrice.vue'
import ZhengCaiInfo from './tkComponent/tkEdit/ZhengCaiInfo.vue'
	export default{
		name:'TkEdit',
		components:{
			TkCxr,
			TkEditFlightInfo,
			TkFlightPrice,
			ZhengCaiInfo,
			BookRule,
			TermsAndNotices
		},
		mixins:[mixin.commonMixin],
		data(){
			return {
				currentStatus:1,
				statusList:[
					{
						status:'1',
						name:'填写',
					},
					{
						status:'2',
						name:'支付',
					},
					{
						status:'3',
						name:'完成',
					}
				],
				// 提交订单
				tiJiaoLoading:false,
				ifAutoPay:0, // 1 自动支付  0仅提交订单
				sameTkOrderList:[],//是否相同订单
				showSameOrderTip:false, // 是否显示相同订单
				seatList:[], // 座位喜好列表
				// 遮罩对象
				loadingTest:null,
				
				// 是否固定航班信息
				isFixedTkEditRight:false,
				// 是否需要审批
				isWuXuShenPi:true, // 默认无需审批
				
				// 是否显示 预订规则
				isShowBookRule:false,
				// 预订南航时 是否阅读 条款
				isReadNh: true,
				// 是否显示南航条款 与 隐私通知
				isShowTeamNotice: false,
				// 南航会员条款 及 通知 
				tnType:1,  //1条款 2通知
				isOpenRepeatOrder:0,
				repeatCanBook:0,
				
			}
		},
		computed:{
			...mapState({
				tkQuery:state => state.tk.tkQuery,
				tkEdit: state => state.tk.tkEdit,
				chooseFlight:state => state.tk.chooseFlight,
				chooseFlightBack:state => state.tk.chooseFlightBack,
				cxrList:state=>state.tk.cxrList,
				nhrh: state=>state.tk.nhrh,
				chooseCcsqd:state=>state.tk.chooseCcsqd
			}),
			...mapGetters({
				getVipParamterByParNo:'common/getVipParamterByParNo',
			}),
			tripType(){
				return 1
			},
			ccsqdDetail(){
				return this.chooseCcsqd?this.chooseCcsqd.detail:{}
			},
			// 是否隐藏保险列表
			isHiddenBxList(){
				let IsBookStyle2= this.userInfo && this.userInfo.vipCorp && this.userInfo.vipCorp.bookStyle == 2;
				// 仅当因公模式，出差申请单模式，出差申请单预订，当出差申请单详情存在  外勤或内勤字段【internalAffairs】，1 内勤，其他 外勤  当外勤是不显示保险
				return this.tkQuery.tripType == 1 && IsBookStyle2 && this.ccsqdDetail && this.ccsqdDetail.internalAffairs  && this.ccsqdDetail.internalAffairs!=1
			},
			// 单据类型
			orderType(){
				return 11001
			},
			userInfo(){
				return this.$common.getUserInfo();
			},
			project(){
				return this.tkEdit.project || {};
			},
			linker(){
				return this.tkEdit.linker || {};
			},
			defaultBxList(){
				return this.tkEdit.bxList || [];
			},
			// 审批规则
			spgz(){
				return this.tkEdit.spgz || {};
			},
			// 当前行程是否可以选座
			isShowChooseZuo(){
				return this.tkEdit.isShowChooseZuo
			},
			// 是否可以预订 机 + x 产品 
			// 20005 "是否开启机场服务产品与机票预订的关联推荐预订流程。0 都不开启,1 因公推荐,2 因私推荐,3都开启"
			isCanTkXProduct(){
				return this.getVipParamterByParNo('20005',0);  
			},
			showTiJiaoBtn(){
				let bool = true;
				let isHiddenTjBtn = this.getVipParamterByParNo('20019',0,'value2');
				// 显示【提交出票】按钮时，是否能隐藏【提交按钮】
				if(isHiddenTjBtn == 1 && this.showTiJiaoAndChuBtn){
					bool = false
				}
				return bool
			},
			// 是否显示提交并出票按钮
			showTiJiaoAndChuBtn() {
			  var bool = false;
			  var isAutoPayCanShu = this.getVipParamterByParNo('20019',0);
			  var userInfo = this.$common.getUserInfo() || {};
			  var useAccount = userInfo.empInfo && userInfo.empInfo.useAccount;
			  // 1. 开启自动出票参数 1   2.无需审批     3.因公模式    4.公司支付模式
			  if (isAutoPayCanShu == 1 && this.isWuXuShenPi && this.tkQuery.tripType == 1 && useAccount) {
			    bool = true;
			  } else {
			    bool = false;
			  }
			  return bool;
			},
            nhShow(){ // 南航入会判断容错判断,避免快速预定没有此参数的时候页面白屏报错
                    let isShow;
                    if(this.chooseFlight && this.chooseFlight.airline){
                        this.chooseFlight.airline== 'CZ'? isShow=true:isShow=false
                    }else if(this.chooseFlightBack && this.chooseFlightBack.airline){
                        this.chooseFlightBack.airline== 'CZ'? isShow=true:isShow=false
                    }else{
                        isShow=false
                    }
                    return isShow
            },
			
			// 违背原因
			clbzMap(){
				var obj = {
					// 当前使用谁的差旅标准
					useNameclbz:this.tkQuery.useNameclbz,
					// 去程违背原因
					violateCode: this.chooseFlight.chooseCabin.violateCode || '',
					violateItem: this.chooseFlight.chooseCabin.violateItem || '',
					reasonCode: this.chooseFlight.reasonCode || '',
					reasonDesc: this.chooseFlight.reasonDesc || '',
					
				}
				// 返程违背原因
				if(this.chooseFlightBack && this.chooseFlightBack.chooseCabin && this.chooseFlightBack.chooseCabin.violateItem ){
					obj.violateCode1 = this.chooseFlightBack.chooseCabin.violateCode || '';
					obj.violateItem1 = this.chooseFlightBack.chooseCabin.violateItem || '';
					obj.reasonCode1 = this.chooseFlightBack.reasonCode || '';
					obj.reasonDesc1 = this.chooseFlightBack.reasonDesc || '';
				}
				
				return obj
			},
		
		},
		created() {
			// 1.初始化信息
			this.init();
			// 2.获取当前是否显示选座
			this.getShowChooseZuo();
			
		},
		mounted() {
			// dom加载完后,进行监听配置
			this.$nextTick(()=>{
				// 1. 航班列表滚动监听
				this.addScrollListen();	
				
				
			})
			// 获取行李额信息
			this.getBaggageInfo();
			// 获取重复订单参数
			this.getRepeatOrderParam();
			
		},
		destroyed(){
			// 移除滚动监听
			this.removeScrollListen();
		
		},
		methods:{
			...mapMutations({
				SETTkQuery: 'tk/setTkQuery',
				SETCxrList:'tk/setCxrList',
				SETTkEdit:"tk/setTkEdit",
				SETChooseFlight: 'tk/setChooseFlight',
				SETChooseFlightBack: 'tk/setChooseFlightBack',
			}),
			// 获取企业是否设置，重复订单验证
			getRepeatOrderParam(){
				this.$conn.getConn('commu.getVipCorpParameter')({paramNo:1335},(res)=>{
					var data = res.data || {};
					this.isOpenRepeatOrder = data.value1;
					this.repeatCanBook = data.value2;
					
				},(err)=>{
					// this.$message.error(err.tips)
				})
			},
			// 获取行李额,更新下服务器上行李额缓存
			getBaggageInfo(){
			
			  let flightList =  this.getValidFlightList();

			  for(let k=0;k<flightList.length;k++){
			      this.getBaggageOneAjax(flightList[k])
			  }
			
			},
			// 获取单个获取行李额ajax
			getBaggageOneAjax(flightItem){
			  let params = {
			    cacheId: flightItem.cacheId,
			    journeyId:flightItem.chooseCabin?flightItem.chooseCabin.journeyId :'',
			    priceId:  flightItem.chooseCabin?flightItem.chooseCabin.priceId :'',
			  }
			  if(!params.priceId){
			    return
			  }
			  this.getBaggageAjax(params,(resp)=>{ },(err)=>{})
			},
			// 获取行李额
			  getBaggageAjax(queryObj,succFn,errorFn){
			    let queryBaggage = this.$conn.getConn("tkQuery2023.queryBaggage");
			    queryBaggage(queryObj, (resp) => {
			      succFn&&succFn(resp)
			    }, (err) => {
			      errorFn&&errorFn(err)
			    })
			  },
			// 处理单程和往返，直飞和中转航班集合
			getValidFlightList(){
				let flightList = [];
				// 去程
				var chooseFlight = this.$common.deepCopy(this.chooseFlight);
				if(chooseFlight.isTransfer == 1){ // 中转
					flightList.push(chooseFlight.seg1 || {});
					flightList.push(chooseFlight.seg2 || {});
				}else{
					flightList.push(chooseFlight)
				}
				// 返程
				if(this.tkQuery.travelType == 2){
					var chooseFlightBack = this.$common.deepCopy(this.chooseFlightBack);
					if(chooseFlightBack.isTransfer == 1){ // 中转
						flightList.push(chooseFlightBack.seg1 || {});
						flightList.push(chooseFlightBack.seg2 || {});
					}else{
						flightList.push(chooseFlightBack)
					}
				}
				return flightList
			
			},
			
			addScrollListen(){
				window.addEventListener("scroll",this.handleScroll);
			},
			removeScrollListen(){
				window.removeEventListener('scroll', this.handleScroll)
			},
			// 滚动监听处理操作
			handleScroll(){
				 let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
		
				        if (scrollTop > 0) {
				          //滚动大于0的时候要做的操作
				        }
				        if (scrollTop > 100) {
				          //大于200的时候要做的操作
						  this.isFixedTkEditRight = true;
				        }else{
							this.isFixedTkEditRight = false;
						}
			},
			init(){
				// 初始化联系人
				let linker = {
					linkEmail:this.linker.linkEmail || this.userInfo.empInfo.email || '',// (string, optional): 联系邮箱 ,
					linkMan  :this.linker.linkMan || this.userInfo.empInfo.empName || '',// (string, optional): 联系人 ,
					linkPhone  :this.linker.linkPhone || this.userInfo.empInfo.phoneNumber || '',// (string, optional): 联系电话 ,
				}
				this.SETTkEdit({linker:linker})
			},
			// 查看预订须知
			lookBookBule(){
				this.isShowBookRule = true;
			},
			// 打开南航条款 或 通知
			lookTermNotice(type){
				this.tnType = type;
				this.isShowTeamNotice = true;
			},
			//南航条款 选择 取消
			chooseReadNh(){
				this.isReadNh = !this.isReadNh
			},
			// 当前航程是否可以选座
			//是否显示选座  POST /1.0.0/findAirportOpenService
			getShowChooseZuo() {
			  var szmStr = '';
			  if (this.tkQuery.travelType == 1) {
			    szmStr =  this.chooseFlight.departAirport;
				  // this.tkQuery.depszm;
			  } else {
			    // szmStr = this.tkQuery.depszm + ',' + this.tkQuery.arrszm;
				szmStr = this.chooseFlight.departAirport + ',' + this.chooseFlightBack.departAirport;
			  }
			  var queryString = {
			    departureAirportCode: szmStr
			  };
			  var url = 'tkBook.findAirportOpenService';
			  this.$conn.getConn(url)(queryString, (resp) => {
			    var data = resp.data || {};
			    if (data.isOpenAirportService == 1) {
			      this.SETTkEdit({isShowChooseZuo:true})
				  // 3.获取当前座位喜好列表
				  this.getSeatList();
			    }else{
				  this.SETTkEdit({isShowChooseZuo:false})
				}
			  },(err)=>{
				  this.SETTkEdit({isShowChooseZuo:false})
			  })
			},
			//获取当前的座位喜好 
			getSeatList(){
				var queryString = {};
				var url = 'tkBook.getTkOrderPsgSeatList';
				this.$conn.getConn(url)(queryString, (resp) => {
				  var data = resp.data || [];
				  this.seatList = data;
				},(err)=>{
					
				})
			},
			//********************** 以下： 页面数据获取与页面操作 *******************
			// 获取项目对象
			chooseProject(item){
				this.SETTkEdit({project:item})
			},
			// 获取差旅公共信息中, 成本中心关联的出行人对象,并存储起来
			getCxrList(cxrList){
				this.SETCxrList(cxrList)
			},
			// 选择审批规则
			chooseSpgz(item){
				this.SETTkEdit({spgz:item})
			},
			// 获取联系人对象
			getLinker(linker){
				this.SETTkEdit({linker:linker})
			},
			// 获取保险产品列表
			chooseBxcp(bxList){
				this.SETTkEdit({bxList:bxList})
			},
			// 选择违背原因
			chooseReason(obj){
				var newFlightItem = {};
				// 去程违背
				if(obj.weiType==1){
					newFlightItem = this.$common.deepCopy(this.chooseFlight)
					for(var key in obj){
						newFlightItem[key] = obj[key];
					}
					this.SETChooseFlight(newFlightItem);
				}else{ // 返程违背
					newFlightItem = this.$common.deepCopy(this.chooseFlightBack)
					for(var key in obj){
						newFlightItem[key] = obj[key];
					}
					this.SETChooseFlightBack(newFlightItem);
				}
			},
			
			// 获取政采信息
			// @chooseBudgetUnit="chooseBudgetUnit"
			// @chooseBank="chooseBank"
			// 选择预算单位
			chooseBudgetUnit(budgetUnit){
				this.SETTkEdit({
					'budgetUnit':budgetUnit
				})
			},
			// 选择银行
			chooseBank(bankName){
				this.SETTkEdit({
					'bankName':bankName
				})
			},
			changeGpValidType(gpValidType){
				this.SETTkEdit({
					'gpValidType':gpValidType
				})
			},
			// 修改是否需要审批
			changeWuXuShenPi(val){
				this.isWuXuShenPi = val;
			},
			
			
			/*
			*   提交订单前操作注意事项
			*	a.是否需要审批
			*	b.是否选择违背原因
			*	c.是否选择项目
			*	d.是否选择成本中心
			*	e.是否出差单号必填
			*	f.是否是往返航班
			*	g.是否是自动并出票按钮
			*	h.是否是抢票订单
			*
			*	机票预订下单流程：
			*	1. 重复订单验证
			*	2. 创建pnr生成
			*	3. 提交机票订单
			*	4. 提交保险订单
			*	5. 判断是否需要值机服务提示
			*	6. 跳转支付页
			* 	 
			*  2023接口改版： 创建pnr 和 生成机票订单 和 保险订单都为一个接口
			* 
			* 
			* 
			*  提交订单 和  提交并出票
			*	1.重复订单验证
			*   2.生成pnr
			*   3.机票下单
			*   
			*   注意点： 1.防止重复点击 tiJiaoLoading
			*           2.提交订单 和 提交订单并出票操作   ifAutoPay  是否自动支付  1 自动支付  0 仅提交订单
			* 
			***/
			 // 下一步
			nextStep(type){
				
				this.ifAutoPay = type || 0;
				// 开启重复点击防护
				this.tiJiaoLoading = true;
				
				// 开启遮罩保护
				this.loadingTest =	this.openLoading();
				// 验证参数
				let validObj = this.validTkEditQuery();
				// 若验证通过，则开始提交订单
				
				
				if(validObj.bool){
					this.bookOneStep();
					// // 仅希安康走重复订单验证
					// if(this.$route.query.sqdType=='xak'){
					// 	this.bookOneStep();
					// }else{
					// 	this.bookTwoStep();
					// }
					
					
				}else{
					this.tiJiaoLoading = false;
					this.loadingTest.close();
				}
				 
			},
			// 验证参数
			validTkEditQuery(){
				let obj = {
					bool:true,
					
				};
				// 验证内容
				// 1.验证证件信息
				var validCxrListRule = this.validCxrList() || {};
				if(!validCxrListRule.bool){
					this.$message.error(validCxrListRule.msg)
					obj.bool = false;
				}
				// 2.验证差旅信息、项目、成本中心、审批规则
				if(this.tkQuery.tripType == 1){
					var clzcRule = this.$refs['tkTravelInfo']&&this.$refs['tkTravelInfo'].validTravelInfo();
					if(!clzcRule.bool){
						this.$message.error(clzcRule.msg)
						obj.bool = false;
					}
				}
				
				// 3.验证政采信息和联系人信息
				var tkEditRule = this.validTkEditRule();
				if(!tkEditRule.bool){
					this.$message.error(tkEditRule.msg)
					obj.bool = false;
				}
				
				return obj				
			},
			// 验证出行人信息 
			validCxrList(){
				var obj = {
					bool:true,
					msg:'',
				}
				
				if(this.cxrList.length == 0){
					return {
						bool:false,
						msg:"请至少选一个乘机人！"
					}
				}
				var cardObj = {
					1: 'NI', // 身份证
					2: 'PP', // 护照
					// 3: 'ID' //其他
				};
				for(var k=0;k<this.cxrList.length;k++){
					var cxrItem = this.cxrList[k] || {};
					
					var checkIdCardObj = this.checkIdCard(cxrItem,k);
					
					let cardType = cardObj[cxrItem.useCardType] || 'ID';
					 // 乘机人纯汉字 或者 字母加/ 匹配 校验
					 let cxrName = cxrItem.empName || cxrItem.chName || cxrItem.cxrName || '';
					 let engName = cxrItem.engName || '';
					 let psgName = cardType == 'NI'?cxrName:engName;
					 psgName = psgName.replace(/·/g, ''); //  少数名族中姓名带 点
					 
					 
					if(!psgName){
						obj.msg = `第${k + 1}位客人的姓名不能为空！`;
						obj.bool = false;
						return obj
					}else{
						if(this.$common.regZiMu(psgName) || this.$common.regHanZi(psgName) || this.$common.regEnglishName(psgName)){
							
						}else{
							obj.msg = `第${k + 1}位客人的姓名有误！`;
							obj.bool = false;
							return obj
						}
						
					}
					if(!checkIdCardObj.bool){
						obj.msg = checkIdCardObj.msg;
						obj.bool = false;
						return obj
					}
					// 手机号
					if(!cxrItem.phoneNumber){
						
						obj.msg = `第${k + 1}位客人[${cxrItem.cxrName}]的手机号不能为空！`;
						obj.bool = false;
						return obj
					}else{
						if (!(/^1[3456789]\d{9}$/.test(cxrItem.phoneNumber))) {
							
							obj.msg = `第${k + 1}位客人[${cxrItem.cxrName}]的手机号格式不正确！`;
							obj.bool = false;
							return obj
						}
					}
					
				}
				return obj
				
			},
			// 验证单证信息
			checkIdCard(cxrItem,cxrIndex){
				var obj = {
					bool:true,
					msg:"",
				}
				var useCardType = cxrItem.useCardType;
				var cardItem = cxrItem.idcardList.filter((item)=>{
					return (item.idType == useCardType)
				})[0] || {}
				
				
				// 当前核验身份证信息
				if(useCardType == 1){
					var regcardId = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
					var age = 0;
					
					if(!cardItem['idNo'] || (cardItem['idNo'] && !regcardId.test(cardItem['idNo']))  ){
						obj.bool = false;
						obj.msg = `第${cxrIndex + 1}位客人[${cxrItem.cxrName || ''}]的身份证信息有误`;
						return obj
					}
					// 12周岁 验证
					age = this.$common.verifyIdentity(1,cardItem['idNo'])
					if(age < 12){
						obj.bool = false;
						obj.msg = `第${cxrIndex + 1}位客人[${cxrItem.cxrName || ''}]不足12周岁，线上不允许预订儿童/婴儿票请联系客服处理`;
						return obj
					}
					// // 验证三方协议号 因公
					// if(this.chooseFlight.chooseCabin.productType == 3){
					// 	if(!(age > 19 && age<61)){
					// 		obj.bool = false;
					// 		obj.msg = `乘机人[${cxrItem.cxrName || ''}]，年龄不符合预订三方协议号产品，请联系客服处理`;
					// 		return obj
					// 	}
					// }
					// if(this.tkQuery.travelType==2){
					// 	if(this.chooseFlightBack.chooseCabin.productType == 3 ){
					// 		if(!(age > 19 && age<61)){
					// 			obj.bool = false;
					// 			obj.msg = `乘机人[${cxrItem.cxrName || ''}]，年龄不符合预订三方协议号产品，请联系客服处理`;
					// 			return obj
					// 		}
					// 	}
					// }
					
					
					// 当前核验护照信息
				}else if(useCardType == 2){
					var age = 0;
					// 证件号
					if(!cardItem['idNo']){
						obj.bool = false;
						obj.msg = `第${cxrIndex + 1}位客人[${cxrItem.cxrName || ''}]的护照号有误`;
						return obj
					}
					// 有效期
					
					if(!cardItem['validDate'] || (cardItem['validDate'] &&  new Date(cardItem['validDate'].replace(/-/g,'/')) < new Date())){
						obj.bool = false;
						obj.msg = `第${cxrIndex + 1}位客人[${cxrItem.cxrName || ''}]的有效期有误`;
						return obj
					}
					// 签发地
					if(!cardItem['placeOfIssue']){
						obj.bool = false;
						obj.msg = `第${cxrIndex + 1}位客人[${cxrItem.cxrName || ''}]的签发地有误`;
						return obj
					}
					// 护照 出生日期
					if(!cxrItem.born){
						obj.bool = false;
						obj.msg = `第${cxrIndex + 1}位客人[${cxrItem.cxrName || ''}]出生日期为空，请选择出生日期`;
						return obj
					}
					// 12周岁 验证
					age = this.$common.verifyIdentity(2,cxrItem.born)
					if(age < 12){
						
						obj.bool = false;
						obj.msg = `第${cxrIndex + 1}位客人[${cxrItem.cxrName || ''}]不足12周岁，线上不允许预订儿童/婴儿票请联系客服处理`;
						return obj
					}

					// // 验证三方协议号 因公
					// if(this.chooseFlight.chooseCabin.productType == 3 ){
					// 	if(!(age > 19 && age<61)){
					// 		obj.bool = false;
					// 		obj.msg = `乘机人[${cxrItem.cxrName || ''}]，年龄不符合预订三方协议号产品，请联系客服处理`;
					// 		return obj
					// 	}
					// }
					// if(this.tkQuery.travelType==2){
					// 	if(this.chooseFlightBack.chooseCabin.productType == 3 ){
					// 		if(!(age > 19 && age<61)){
					// 			obj.bool = false;
					// 			obj.msg = `乘机人[${cxrItem.cxrName || ''}]，年龄不符合预订三方协议号产品，请联系客服处理`;
					// 			return obj
					// 		}
					// 	}
					// }
					
						
					
				}else{
					// 证件号
					if(!cardItem['idNo']){
						obj.bool = false;
						obj.msg = `第${cxrIndex + 1}位客人[${cxrItem.cxrName || ''}]的证件信息有误`;
						return obj
					}
				}
				
				return obj
			},
			// 验证机票填写规则
			validTkEditRule(){
			  var obj = {
			    bool:true,
			    msg:'',
			  };
			  // 政采票验证  tkType
			  if(this.tkQuery.tkType == 1&& this.tkQuery.tripType == 1){
			    if(this.tkEdit.gpValidType == 1 && !this.tkEdit.budgetUnit){
			      obj.msg = "当前预订政采票，请选择预算单位！";
			      obj.bool = false;
			      return obj
			    }else if(this.tkEdit.gpValidType == 2 && !this.tkEdit.bankName){
			      obj.msg = "当前预订政采票，请选择公务卡开户银行！";
			      obj.bool = false;
			      return obj
			    }
			
			  }
			  
			  // 联系人和联系电话验证
			  if(!this.linker.linkMan){
			  	obj.msg = "请填写联系人姓名！";
			  	obj.bool = false;
			  	return obj
			  }
			  if(!this.linker.linkPhone){
			  	obj.msg = "请填写联系人电话！";
			  	obj.bool = false;
			  	return obj
			  }
					
			  return obj
			},
			// 不允许预订提醒
			validRepeatBook(succFn){
				// 开启重复订单，不允许预订提醒！
				if(this.isOpenRepeatOrder == 1 && this.repeatCanBook != 1){
						this.loadingTest.close();
						this.tiJiaoLoading = false;
						this.$alert(`企业开启了重复订单，不允许预订的管控！`, {
									confirmButtonText: '确定',
									callback: action => {
										
								}
						});
						return 
					
				}else{
					succFn&&succFn()
				}

			} ,
			// 取消相同订单验证弹窗
			cancelBookSameOrder(){
				this.showSameOrderTip = false;
				this.tiJiaoLoading = false;
			},
			// 相同订单继续预订
			confirmBookSameOrder(){
				this.showSameOrderTip = false;
				
				this.validRepeatBook(()=>{
					this.bookTwoStep({ignoreRepeat:true})
				})
		
			},
			//第一步： 重复订单验证
			bookOneStep(){
				if(this.$route.query.sqdType=='xak'){
					this.validSameOrderForXak();
				}else{
					if(this.isOpenRepeatOrder == 1){
						this.validSameOrderForCommon();
					}else{
						this.bookTwoStep();
					}
				
				}
			},
			// 验证相同订单  common
			validSameOrderForCommon(){
				let psgList = this.getPsgList(1) ;
				let rangeList = this.getRangeList(5) ;
				// var queryString = {
				//   "idnoList": psgList,
				//   "rangeList": rangeList
				// };
				var queryString = {
				  "psgList": psgList,
				  "rangeList": rangeList
				};
			
				let querySameOrderUrl = 'tkQuery2023.queryRepeatedItinerary';
				 this.$conn.getConn(querySameOrderUrl)(queryString, (resp) => {

					let data= resp.data || '';
					if(data){

						this.validRepeatBook(()=>{
							this.loadingTest.close();
							this.tiJiaoLoading = false;
							this.$alert(data, {
							    confirmButtonText: '继续预订',
							    callback: action => {
									
									this.loadingTest =	this.openLoading();
									this.tiJiaoLoading = true;
									this.bookTwoStep();	
								}
							});
						})

						
					}else{
						this.bookTwoStep();
					}
					return 
					// let data = resp.data || {}
					// this.sameTkOrderList = data || [];
					
					// if (this.sameTkOrderList.length > 0) { // 1.存在重复订单，弹出弹窗  取消、确定操作
					//   this.showSameOrderTip = true;
					//   this.loadingTest.close();
					   
					// } else if(this.sameTkOrderList.length ==0 && this.$route.query.sqdType=='xak') { // 希安康的直接跳过pnr 直接下单
                    //     this.createPnrFast()
                    // }
                    // else { // 无重复订单  
					//   this.bookTwoStep();
					// }
				 },(err)=>{
					 this.tiJiaoLoading = false;
					 this.loadingTest.close();

					 this.$alert(err.tips, {
							    confirmButtonText: '确定',
							    callback: action => {
									
							}
					});
				 })
			},

			// 验证相同订单   希安康
			validSameOrderForXak(){
				let psgList = this.getPsgList(1) ;
				let rangeList = this.getRangeList(1) ;
				// var queryString = {
				//   "idnoList": psgList,
				//   "rangeList": rangeList
				// };
				var queryString = {
				  "psgList": psgList,
				  "rangeList": rangeList
				};
				// let querySameOrderUrl = 'tkBook.querySameOrder2';
				let querySameOrderUrl = 'tkQuery2023.querySameOrder';
				 this.$conn.getConn(querySameOrderUrl)(queryString, (resp) => {
					let data = resp.data || {}
					this.sameTkOrderList = data || [];
					
					if (this.sameTkOrderList.length > 0) { // 1.存在重复订单，弹出弹窗  取消、确定操作
					  this.showSameOrderTip = true;
					  this.loadingTest.close();
					   
					} else if(this.sameTkOrderList.length ==0 && this.$route.query.sqdType=='xak') { // 希安康的直接跳过pnr 直接下单
                        this.createPnrFast()
                    }
                    else { // 无重复订单  
					  this.bookTwoStep();
					}
				 },(err)=>{
					 this.tiJiaoLoading = false;
					 this.loadingTest.close();

					 this.$alert(err.tips, {
							    confirmButtonText: '确定',
							    callback: action => {
									
							  	}
					});
				 })
			},



            createPnrFast(){
                let psgValidObj = this.getPsgList(3);
                // if (psgValidObj.book != 1) {
                //         return false
                //     }
                // let psgList = psgValidObj.list || []; 
                let params = {
					ccsqdId: this.tkQuery.ccsqdId,// 出差申请单id ,
					empRank: this.tkQuery.empRank,// 员工职级 C端默认传0 ,
					linkEmail: this.linker.email,// 联系邮箱 ,
					linkMan: this.linker.man ||'',// 联系人 ,
					linkTel: this.linker.phone ||'',// 联系电话 ,
					psgList: psgValidObj,// 乘机人集合 ,
					routeId: this.tkQuery.routeId, // 行程id
					projectCode: this.project.projectCode||'',// 项目代码 C端不传 ,
					projectId: this.project.id||'',// 项目id C端不传 ,
					projectName: this.project.projectName||'',// 项目名称 C端不传 ,
					bookBxBean:this.getBookBxBean(this.defaultBxList,this.cxrList)
				}
                let simpleBookOrder = this.$conn.getConn("xakCcsqd.simpleBookOrder");
                simpleBookOrder(params,resp=>{
                let data = resp.data || {};
					this.orderList = [{orderId: data.orderId}]
					this.goToNextPage();
				
                })

            },
			//第二步： 创建pnr
			bookTwoStep(sameOrderObj){
				 this.bookThreeStep([],[],sameOrderObj)
			},
			

			//第三步： 提交订单   暂未处理 gp票逻辑 和 出差申请单逻辑
			bookThreeStep(parNoList,parIdList,sameOrderObj){
				let rangeList = this.getRangeList(3,parNoList,parIdList);
				let psgList = this.getPsgList(3) ;
				var isReadNh = 0;
				if((this.chooseFlight.airline == 'CZ' || this.chooseFlightBack.airline=='CZ') && this.nhrh){
					isReadNh = this.isReadNh?1:0;
				}
				sameOrderObj = sameOrderObj || {};
				var queryString = {
					  "appId": this.tkEdit.spgz?this.tkEdit.spgz.gzId:"",
					  "bankName": this.tkEdit.bankName,   //  预算单位 gpValidType为 2 时必填 
					  "budgetUnit": this.tkEdit.budgetUnit, //  预算单位 gpValidType为 1 时必填 
					  // "ccsqdId": this.tkQuery.tripType == 1? this.tkQuery.ccsqdId :'',//出差申请单id
					  // "ccsqdNo": this.tkQuery.tripType == 1? this.tkQuery.ccsqdNo :'',//出差申请单编号
					  // "costCenterCode": "",
					  // "costCenterId": "",
					  // "costCenterName": "",
					  // "empRank": this.tkQuery.empRank,
					  "createAirlineMember": isReadNh,
					  "gpValidType": this.tkEdit.gpValidType, //GP票验证类型 1预算单位 2开户行 ,
					  "ifAutoPay": this.ifAutoPay,
					  "linkEmail": this.linker.linkEmail,
					  "linkMan": this.linker.linkMan,
					  "linkTel": this.linker.linkPhone,
					  "orderFrom": 10503,
					  "postCode": "",
					  // "projectCode": this.project.projectCode || '',
					  // "projectId": this.project.id || '',
					  // "projectName": this.project.projectName || '',
					  "psgList": psgList,
					  "rangeList": rangeList,
					  "rangeType": this.tkQuery.travelType,
					  "reciAddr": "",
					  "reciPhone": "",
					  "reciPient": "",
					  // "routeId": this.tkQuery.routeId, // 行程
					  "sendMode": 0,
					  "supplierId": this.chooseFlight.chooseCabin?this.chooseFlight.chooseCabin.supplierId:"", // 供应商id
					  // "travelNo": this.tkQuery.ccsqdNo,
					  "travelStandard": "",
					  "tripType": this.tkQuery.tripType,
					  "international":1,
					  bookBxBean:this.getBookBxBean(this.defaultBxList,this.cxrList)
					};
					// 因公模式
					if(this.tkQuery.tripType == 1){
						queryString['empRank'] = this.tkQuery.empRank;
						
						queryString['appId'] = this.spgz.gzId;
						queryString['projectCode'] = this.project.projectCode || '';
						queryString['projectId'] =  this.project.id || '';
						queryString['projectName'] = this.project.projectName || '';
						// 出差申请单模式
						if(this.tkQuery.bookStyle == 2){ 
							queryString['ccsqdId'] = this.tkQuery.ccsqdId|| '';
							queryString['ccsqdNo'] = this.tkQuery.ccsqdNo|| '';
							queryString['travelNo'] = this.tkQuery.ccsqdNo|| '';
							queryString['routeId'] = this.tkQuery.routeId|| '';
							
						}
					}
					// 政采票
					if(this.tkQuery.tkType ==1){
						queryString['bankName'] = this.tkEdit.bankName;//  开户行 gpValidType为 2 时必填
						queryString['budgetUnit'] = this.tkEdit.budgetUnit; //  预算单位 gpValidType为 1 时必填 	
					}
					// 重复订单验证 若管控
					if(sameOrderObj.ignoreRepeat){
						queryString.ignoreRepeat = true;
					}
					// let createOrderUrl = 'tkBook.bookOrder2';
					let createOrderUrl = 'tkQuery2023.createOrder';
					this.$conn.getConn(createOrderUrl)(queryString, (resp) => {
						var data = resp.data || {};
						let orderIds = data.orderIds || '';
						let orderList = orderIds.split(',') || [];
						this.orderList = orderList.map(orderItem=>{
							return {
								orderId:orderItem
							}
						}) || [];
						// this.orderList = data.orderList || [];
						//  如果价格发生变化
						if(data.priceFlag == 1){
							let changePrices = data.changePrices  || [];
							var tips = this.getNewTips(changePrices);
							if (tips) {
								 this.$confirm(tips, '是否继续预订', {
								  confirmButtonText: '确定',
								  cancelButtonText: '取消',
								  type: 'warning'
								}).then(() => {
									// 主动去送审，如果需要送审，后台自动送审，否则不送
									this.gotoOrderSendBmp(orderIds,()=>{
										this.goToNextPage();
									})
								  
								}).catch(() => {
									// 取消订单
								  this.cancleAllOrder(this.orderList);         
								});
								
							} else {
								this.goToNextPage();
							} 
							return
						}
						
						// 如果  【当前价格已违背本公司差旅标准，请返回重新选择机票】
						if(data.priceFlag == 2){
							let violateReason  = data.violateReason || '';
							let tipsStr2 = `${violateReason || ''},请返回重新选择机票`;
							// let tipsStr2 = "当前价格已违背本公司差旅标准，请返回重新选择机票";
							this.loadingTest.close();
							this.tiJiaoLoading = false;
							this.$alert(tipsStr2, {
							    confirmButtonText: '我已了解',
							    callback: action => {
									// 重新查询航班
									this.changeFlight();
									// this.cancleAllOrder(this.orderList,()=>{
									
									// });	
								}
							});
							return
						}
						
						//  如果航班时间发生变动
						if(data.depTimeChanged == 1){
							let orderListForValidDate = data.orderList  || [];
							var tips = this.getDateValidTips(orderListForValidDate);
							if (tips) {
								 this.$confirm(tips, '航班变动提醒', {
								  confirmButtonText: '继续预订',
								  cancelButtonText: '取消订单',
								  type: 'warning'
								}).then(() => {
									// 主动去送审，如果需要送审，后台自动送审，否则不送
									this.gotoOrderSendBmp(orderIds,()=>{
										this.goToNextPage();
									})
								  
								}).catch(() => {
									// 取消订单
								  this.cancleAllOrder(this.orderList);         
								});
								
							} else {
								this.goToNextPage();
							} 
							return
						}


						
						this.goToNextPage();
					
					},(err)=>{
						// this.canclePnr(parNoList,parNoList);
						this.loadingTest.close();
						this.tiJiaoLoading = false;
						this.$alert(err.tips,'下单失败')	
						var tips = err.tips;
						var errCode = err.errCode;
						// 验证乘机人证件号
						if (errCode == '8022' || errCode == '5068') {
							var idCardBeanList = [];
							for (var k = 0; k < psgList.length; k++) {
								idCardBeanList.push({
									psgName: psgList[k]['psgName'],
									idNo: psgList[k]['idno'],
								})
							}
											
							var validateIdCardObj = {
								idCardBeanList: idCardBeanList
							}
							this.$confirm(tips, '温馨提示', {
							  confirmButtonText: '继续预订',
							  cancelButtonText: '取消',
							  type: 'warning'
							}).then(() => {
							  this.validateIdCard(validateIdCardObj);
							}).catch(() => {
						       
							});
				
						} else if(errCode == '8008'){
							this.$confirm(tips, '温馨提示', {
							  confirmButtonText: '继续预订',
							  cancelButtonText: '取消',
							  type: 'warning'
							}).then(() => {
							  this.loadingTest =	this.openLoading();
							  this.tiJiaoLoading = true;
							  this.bookTwoStep({ignoreRepeat:true})
							}).catch(() => {
							   
							});
							
						}else {
							this.$alert(tips, {
							    confirmButtonText: '确定',
							    callback: action => {
									if (errCode == 8010 || errCode == 5005) {
										// 重新查询航班 
										this.changeFlight();
									}
							  }
							});
							
						}		 
					})
				
			},
			
			// 机票后台自动送审，如果继续预订  orderSendBmp
			//  POST /1.0.0/et/orderSendBmp  订单送审 
			gotoOrderSendBmp(orderId,callBack){
				let queryUrlForOrderSendBmp = 'tkQuery2023.orderSendBmp';
				let queryString = {
					orderPk:orderId
				}
				this.$conn.getConn(queryUrlForOrderSendBmp)(queryString, (resp) => {
					callBack&&callBack()
				},(err)=>{
					callBack&&callBack()
				})
			},
			
			// 取消订单，可能存在多个
			cancleAllOrder(orderList,callback){
				// 使用 Promise.all 以在三个数据接口均异步成功后，执行回调逻辑
				
				let orderListAjax = orderList.map(orderItem=>{
					return this.cancelSingleOrder(orderItem.orderId)
				})
				Promise.all(orderListAjax).then(values => {
				
					this.loadingTest.close();
					this.tiJiaoLoading = false;
				    callback&&callback()
				}).catch((reason)=>{
					this.loadingTest.close();
					this.tiJiaoLoading = false;
					callback&&callback()
				});
			},
			// 取消单个订单
			cancelSingleOrder(orderId){
				
				return new Promise((resolve, reject) => {
					if(!orderId){
						reject('订单号不存在')
					}
					let cancleSingleAjax = this.$conn.getConn('tkQuery2023.tkOrderCancel2');
					cancleSingleAjax({orderId:orderId}, (res) => {
						resolve(res.data)
					}, (err) => {
						reject(err)
					
						//					common.tips('保险订单下单失败！');
					});
				})
				
			},
			// 去下一个页面
			//  机 + x 页面  // 机票订单详情  // 全部订单页面 // 
			goToNextPage(){
				this.loadingTest.close();
			  if(this.orderList.length == 1){
			    // 20005 "是否开启机场服务产品与机票预订的关联推荐预订流程。0 都不开启,1 因公推荐,2 因私推荐,3都开启"
			    if ((this.isCanTkXProduct == 1 && this.query.tripType == 1) || (this.isCanTkXProduct == 2 && this.query.tripType == 2) ||
			      this.isCanTkXProduct == 3) {
			      this.getTkOrderDetailBytjy(this.orderList[0]['orderId']);
			    } else {
			      
			      if (this.ifAutoPay == 1) {
			        
			        this.$router.push({
			          path: '/yjDetail/tkOrderDetail',
			          query: {}
			        })
			      } else {
			        this.$router.push({
			          path: '/yjDetail/tkOrderDetail',
			          query: {
			            orderId: this.orderList[0]['orderId']
			          }
			        })
			      }
			    }
			  }else if(this.orderList.length == 0){ // 福老板说，不会出现这种情况
					this.$message('系统没有生成订单！')
			  }else {
			  
			      this.$router.push({
			        path: '/yjTravel/myTravel/userCenter/order',
			        query: {
			         
			        }
			      })
			    
			  }
			},
			// 航班超时，重新查询航班 或者 更换航班
			changeFlight(){
				// // 加载航班列表   0 ：未查询  1：正在查询  2.查询有结果 3. 查询无结果 4.查询异常
				this.SETTkQuery({
					loadingFlightList: 1,
					isAutoQuery:1,
				});
				this.$router.go(-1);
			},
			
			
			// 推荐页信息
			getTkOrderDetailBytjy(id) {
			  var queryString = {
			    id: id
			  };
			  var url = '/yjDetail/tkOrderDetail';
			  this.$router.push({
			    path: url,
			    query: {
			      orderId: id
			    }
			  })
			 
			 
			},
			// 获取新的tips  根据订单数量  航班时间变动
			getDateValidTips(changeRangelist){
				var tips = '';
				changeRangelist  = changeRangelist  || [];

				if(changeRangelist.length > 0){
					tips = '温馨提示:您预订的机票订单,';
					changeRangelist.map(orderItem=>{
						var str = '';
						if(orderItem.departDateTime  != orderItem.realDepartTime ){
							str=`${orderItem.voyage }${orderItem.flightNo }机票起飞时间已变动！\n预订起飞时间:${orderItem.departDateTime},实际起飞时间:${orderItem.realDepartTime }!`
						}

						tips += str;
					})
				}



				return tips
			},
			// 获取新的tips  根据订单数量
			//  新的tips 不区分直飞和中转
			getNewTips(changePrices ){
				var tips = '';
				changePrices  = changePrices  || [];
				if(changePrices .length>0){
					tips = '温馨提示:您预订的机票订单,';
					changePrices.map(orderItem=>{
						var str = '';
						if(orderItem.orderPrice  < orderItem.validPrice ){
							str=`${orderItem.voyage }${orderItem.flightNo }机票价格已变高！\n原票价:￥${orderItem.orderPrice},现票价:￥${orderItem.validPrice }!`
						}else if(orderItem.orderPrice > orderItem.validPrice ){
							str=`机票价格已变低！\n原票价:￥${orderItem.orderPrice},现票价:￥${orderItem.validPrice }!`
						}
			
						tips += str; 
					})
				}
				
				
				return tips
			},
			// 处理tips
			getTips(list) {
			  var pnrNo1 = '',
			    pnrNo2 = '',
			    tips = '';
			  var salePrice = this.chooseFlight.chooseCabin.salePrice || 0;
			  if (list.length == 1) { // 单程
			    var oldPrice = salePrice || 0;
			    var newPrice = list[0]['fcny'] || 0;
			    pnrNo1 = list[0]['pnrNo'];
			
			    if (pnrNo1 && pnrNo1 != '#12345') {
			      if (list[0]['fcny'] > salePrice) {
			        tips = '温馨提示:您预订的机票订单，价格已变高！\n原票价(不含税费):￥' + oldPrice + ' 现票价(不含税费):￥' + newPrice;
			      } else if (list[0]['fcny'] < salePrice) {
			        tips = '温馨提示:您预订的机票订单，价格已变低！\n原票价(不含税费):￥' + oldPrice + ' 现票价(不含税费):￥' + newPrice;
			      }
			
			    }
			
			  } else if (list.length == 2) {
			    pnrNo1 = list[0]['pnrNo'];
			    pnrNo2 = list[1]['pnrNo'];
			
			    var salePrice2 = this.chooseFlightBack.chooseCabin.salePrice || 0;
			    // 1. 如果当前  第一程、第二程价格都相等        或者    两者都没有值         提示为空
			    // 2. 如果当前  第一程没有值  第二程价格相等   或者    第二程没有值  第一程价格相等
			    // 3. 如果生成  假的pnr
			    // 4. 数字和任何数据类型比较都为  false;
			    if ((list[0]['fcny'] == salePrice && list[1]['fcny'] == salePrice2) || (!list[0]['fcny'] && list[1]['fcny'] ==
			        salePrice2) || (!list[1]['fcny'] && list[0]['fcny'] == salePrice) || (pnrNo1 && pnrNo1 == '#12345' &&
			        pnrNo2 && pnrNo2 == '#12345') || (!list[0]['fcny'] && !list[1]['fcny'])) {
			      tips = '';
			    } else {
			      var oldPrice = salePrice || 0;
			      var newPrice = list[0]['fcny'] || 0;
			      var oldPrice2 = salePrice2 || 0;
			      var newPric2 = list[1]['fcny'] || 0;
			
			      tips = '温馨提示:您预订的机票订单,';
			      if (pnrNo1 && pnrNo1 != '#12345' && !list[0]['fcny'] && newPrice != oldPrice) {
			        tips = tips + '\n第一程价格发生变动！\n原票价(不含税费):￥' + oldPrice + ' 现票价(不含税费):￥' + newPrice;
			      }
			      if (pnrNo2 && pnrNo2 != '#12345' && !list[1]['fcny'] && oldPrice2 != newPric2) {
			        tips = tips + '\n第二程价格发生变动！\n原票价(不含税费):￥' + oldPrice2 + ' 现票价(不含税费):￥' + newPric2;
			      }
			    }
			  }
			  return tips;
			},
			
			// 取消pnr  
			// canclePnr(pnrNoArray) {
			
			//   var pnrStr = pnrNoArray.join('');
			//   var queryString = {
			//     pnrNo: pnrStr
			//   };
			//   this.$conn.getConn('tkBook.cancelPnr')(queryString, (resp) => {
			// 	this.tiJiaoLoading = false;
			//   }, (err) => {})
			//     this.tiJiaoLoading = false;
			// },
			// // 身份证号验证
			validateIdCard(validateIdCardObj){
			
			  this.$conn.getConn('tkBook.validateIdCard')(validateIdCardObj,(resp)=>{
			       this.bookOneStep();
			  },(err)=>{
			      this.$alert(err.tips);
				  this.tiJiaoLoading = false;
				  this.loadingTest.close();
			  })
			
			},
			
			
			
			
			/*
			 *	获取乘机人入参 
			 *  type:{String/Number}
			  		 1: 重复订单验证 [idNo,...]
			  		 2：创建pnr 
			  				{   "card": "string",
			 					"cardType": "string",
			 					"psgName": "string",
			 					"psgPhone": "string",
			 					"psgType": "string"
			 				}
			        3：机票下单
			  				{
								placeOfIssue：'',
								passportPeriod:"",
								empId:"",
								costCenterCode:"",
								costCenterId:'',
								costCenterName:"",
								sex:"",
								sn:"",
								seat:"", // 座位喜好
			  					"idNo": "string",
			 					"idType": "string",
			 					"phoneNumber": "string",
							    "psgName": "string",
							    "psgType": "string"						
			               }
			 */   
				
			getPsgList(type){
				let cardObj = {
				  1: 'NI', // 身份证
				  2: 'PP', // 护照
				  // 3: 'ID' //其他
				};
				let list = this.cxrList.map((cxrItem,cxrIndex)=>{
					
					let idList = cxrItem['idcardList'] || [];
					let chooseIdList = idList.filter((item,index)=>{
						return item.idType ==  cxrItem.useCardType
					}) || [];
					let chooseIdCardObj = chooseIdList[0] || {};
					// NI   PP   ID
					let cardType = cardObj[cxrItem.useCardType]?cardObj[cxrItem.useCardType]:"ID";
					// 中文名
					let cxrName = cxrItem.cxrName?cxrItem.cxrName.replace(/·/g, ''):"";
					// 英文名
					let engName = cxrItem.engName;
					// 乘客名
					//  身份证时，使用中文名   其他，使用英文名
					let psgName = cardType=='NI'?cxrName:engName;
					if(type==1){
						return {
							"idNo": chooseIdCardObj['idNo'] || "",
							"psgName": psgName, //  少数名族中姓名带 点
						}
						// return chooseIdCardObj['idNo'] || ""
					}else if(type == 2){
						let pnrObj = {
							"card": chooseIdCardObj['idNo'],
							"cardType":cardType,
							"psgName":psgName, //  少数名族中姓名带 点
							"psgPhone":cxrItem.phoneNumber,
							"psgType":cxrItem.psgType,
                            "birthday": cxrItem.born,
							"nation":cxrItem.nation,
							"placeOfIssue":cxrItem.placeOfIssue,
							"passportPeriod": chooseIdCardObj['validDate'],							
						};
						return pnrObj
					}else if(type == 3){
						let psgObj = {
							"born": cxrItem.born,
							"costCenterCode": cxrItem.costCenterCode,
							"costCenterId": cxrItem.costCenterId,
							"costCenterName": cxrItem.costCenterName,
							"empId": cxrItem.cxrType == 1?cxrItem.empId:"", //cxrItem.empId,  // 后台：章小宇、彭黎阳说 外来人不传empId ，汪汪兵产品录入任务
							"foreignType": 0,
							"idType":cardType,
							"idno": chooseIdCardObj['idNo'],
							"nation": cxrItem['nation'],
							"passportPeriod": chooseIdCardObj['validDate'],
							"phoneNumber": cxrItem.phoneNumber,
							"placeOfIssue": chooseIdCardObj['placeOfIssue'],
							"psgAliasName": cxrName,
							"psgName": psgName,
							"psgType": cxrItem.psgType,
							"seat": cxrItem.seat, // 座位喜好
							"sex": cxrItem.sex,
							
							// nation:chooseIdCardObj['nation'],
							// placeOfIssue:chooseIdCardObj['placeOfIssue'],
							// passportPeriod:chooseIdCardObj['validDate'],
							// empId:cxrItem.cxrType == 1?cxrItem.empId:"",
							// costCenterCode:cxrItem.costCenterCode,
							// costCenterId:cxrItem.costCenterId,
							// costCenterName:cxrItem.costCenterName,
							// sex:cxrItem.sex,
							// sn:cxrIndex + 1,
							// seat:cxrItem.seat, // 座位喜好
							// "idno": chooseIdCardObj['idNo'],
							// "idType": cardObj[cxrItem.useCardType]?cardObj[cxrItem.useCardType]:"ID",
							// "phoneNumber": cxrItem.phoneNumber,
							// "psgName":  cxrItem.cxrName?cxrItem.cxrName.replace(/·/g, ''):"",
							// "psgType": cxrItem.psgType,
       //                      "birthday": cxrItem.born,
						};
						return psgObj
					}else{
						return cxrItem
					}
					
				}) || [];
			
				return list
			},
			/*
			* 航程相关接口入参：
			*   功能描述： 通过某一个函数获取入参数组中的某一个集合
			*    参数
			*    param1 ：type {String、Number}   1.重复订单参数   2.创建pnr参数  3.预订下单参数
			*    param2 ：pnrNoArray {Array}    专用于机票下单使用  【pnrNo1，pnrNo2，....】
			*   返回值
			*   result：【Array】     直接使用
			* */
			getRangeList(type, pnrNoArray,pnrIdArray) {
				var typeFnObj = {
					1: "getRangeForSameOrder",
					2: "getRangeForPnr",
					3: "getRangeForBookOrder",
					5: "getRangeForSameOrderForCommon",
				};
				var list = [],
				obj1 = {},
				obj2 = {},
				type = type || 1,
				pnrNoArray = pnrNoArray || [],
				pnrIdArray = pnrIdArray || [],
				getRangeFn = this[typeFnObj[type]] || function() {};
					
				// 单程   仅机票下单需要pnr，pnrId
				obj1 = getRangeFn({}, this.chooseFlight, pnrNoArray[0],pnrIdArray[0]);
				list.push(obj1);
				// 返程
				if (this.tkQuery.travelType == 2) {
					obj2 = getRangeFn({}, this.chooseFlightBack, pnrNoArray[1],pnrIdArray[1]);
					list.push(obj2);
				}
					
				return list;
			},
			// 创建重复订单验证航程验证对象
			getRangeForSameOrder(obj, flight) {
                
				var obj = obj || {},
				flight = flight || {};
					
				obj.arriveAirport = flight.arriveAirport;
				obj.departAirport = flight.departAirport;
				obj.departTime = flight.departDate + ' ' + flight.departTime;
				obj.flightNo = flight.flightNo;
				return obj;
			},
			//  创建重复订单验证航程验证对象  通用版
			getRangeForSameOrderForCommon(obj, flight){
				var obj = obj || {},
				flight = flight || {};
					
				obj.arriveAirport = flight.arriveAirport;
				obj.departAirport = flight.departAirport;
				obj.departTime = flight.departDate + ' ' + flight.departTime;
				obj.arriveTime = flight.arriveDate + ' ' + flight.arriveTime;
				
				obj.flightNo = flight.flightNo;
				return obj;
			},
			//  创建pnr航程对象
			getRangeForPnr(obj, flight) {
				var obj = obj || {},
				flight = flight || {},
				chooseCabin = flight.chooseCabin || {};
					
				obj.cacheId = flight.cacheId;
				obj.arriveTerminal = flight.arriveTerminal;
				obj.arriveTime = flight.arriveTime;
				obj.cabinCode = chooseCabin.cabin;
				obj.departTerminal = flight.departTerminal;
				obj.departTime = flight.departTime;
				obj.flightDate = flight.departDate;
				obj.flightNo = flight.flightNo;
				obj.flightInfoId = flight.flightInfoId; // 这是航班管家特殊标识
				obj.productType = chooseCabin.productType;
				obj.protocolNo = chooseCabin.threeProtocol;
				obj.voyage = flight.departAirport + flight.arriveAirport;
				obj.priceId = chooseCabin.priceId;
				obj.arriveDate = flight.arriveDate;
				return obj;
			},
			// 创建机票下单对象
			getRangeForBookOrder(obj, flight, pnrNo,pnrId) {
				var obj = obj || {},
				flight = flight || {},
				chooseCabin = flight.chooseCabin || {};
					
				obj.cacheId = flight.cacheId;
				obj.pnrNo = pnrNo;
				obj.pnrId = pnrId;
				obj.priceId = chooseCabin.priceId;
				obj.rangeList = flight.departDate + '_' + flight.flightNo + '_' + chooseCabin.cabin; // 舱位键 例：2017-07-30_CA1560_Y ,

				// 违背原因，被写在航班上了
				obj.reasonCode = flight.reasonCode || '';
				obj.reasonDesc = flight.reasonDesc || '';

				obj.recommend = flight.recommend;

				// obj.recomCabin = flight.recomCabin;
				// obj.recomDepartTime = flight.recomDepartTime;
				// obj.recomFlightNo = flight.recomFlightNo;
				// obj.recomPrice = flight.recomPrice;
				obj.recomPriceId = chooseCabin.recomPriceId; // 用于有推荐航班，未选择
				obj.coverPriceId = chooseCabin.coverPriceId; // y用于选择了推荐航班

				return obj;
			},
			
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.tkEdit{
		width: 1200px;
		margin: 0 auto;
	}
	.tkEdit-content{
		padding-top: 20px;
		padding-bottom: 600px;
	}
	.tkEdit-left{
		width: 780px;
		height: 100%;
		
	}
	.tkEdit-right{
		width: 400px;
		height: 100%;
		
		// background-color: green;
	}
	.tkEdit-right-content{
		width: 400px;
		position: static;
		top: 0;
	}
	.tkEdit-right-content.fixed{
			position: fixed;
			top: 0;
	}
	.book-rule{
		text-align: left;
		padding: 20px 20px 0px;
	}
	.book-nh{
		text-align: left;
		padding: 20px 0px 0 0;
		.colornh{
			color: #006BB9;
		}
		.colornh-g{
			color: #C4CDDD;
		}
	}
	// 提交订单
	.tk-button-list{
		margin-top: 20px;
		width:100%;
		.ti-jiao-btn{
			width: 100%;
			height: 80px;
			background: #FF9524;
			// box-shadow: 4px 7px 8px 0px rgba(255, 149, 36, 0.16);
			border: none;
			font-size: 24px;
			font-family: '微软雅黑';
			font-weight: 400;
			color: #FFFFFF;
		}
		.half-item{
			width: 45%;
		}
		.half-item.first{
			margin-right: 20px;
		}
		
	}
	
</style>

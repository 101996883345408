<template>
	<div class="vlt-main">
		<el-dialog
			title="低价航班提醒"
			:visible.sync="visible"
			width="1200px"
			:append-to-body="true"
			class="vlt-tit"
			:before-close="handleClose"
		  >
			<div >
				<p style="line-height: 28px;color: #FFC046;">根据贵司规定及所选的出发时间，推荐您预订以下低价航班，但起飞或到达机场有变动，请注意</p>
				<!-- <p style="color:red;line-height: 28px;">{{reason}}</p> -->
		
				<!-- <div  style="margin: 20px 0;max-height: 200px;overflow-y: auto;border: 1px solid #ccc;padding: 20px;">
					
				</div> -->
                <flight-item-card :flightItem="recomFlight" :priceMap="priceMap"></flight-item-card>    
			</div>
			 
		    <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="toBookFlightItem" style="margin-right: 30px;">预订低价机票</el-button>
				<el-button type="primary" v-show="tuiJian20079 == 0" @click="contiuteBook">继续超标预订</el-button>
				<el-button type="primary" v-show="tuiJian20079 == 1" @click="goBackQueryPage">返回查询界面</el-button>
		    </span>
		</el-dialog>
	</div>
</template>

<script>
    import FlightItemCard from './FlightItemCard.vue';
	export default{
		name: 'TuiJianFlightCard',
        components:{
           'flight-item-card': FlightItemCard 
        },
		props: {
			// 显示违背原因组件
			isShow: {
				type: Boolean,
				default:false,
			},
			recomFlight:{
                type: Object,
				default:()=>{
                    return {}
                },
            }
			
		},
        computed:{
            chooseCabin(){
                return this.recomFlight.chooseCabin || {}
            },
            priceMap(){
                let obj = {};
                obj[this.chooseCabin['priceItem']] = this.chooseCabin
                return obj
            },
			 // 低价航班预订  : 0  超标继续预订  1 返回查询界面  
			 tuiJian20079(){
				return this.$common.getVipParamterByParNo(this.$store,'20079',0)
			},
        },
		data() {
			return {
				visible: false,
			}
		},
		watch: {
			isShow: {
				handler(val){
					this.visible = val;
					
				},
				immediate: true
			},

			
		},
		mounted() {
		
		},
		methods: {

			// 选择推荐航班
			toBookFlightItem() {
				this.$emit('toBookFlightItem',this.chooseReasonObj)
			},
            // 继续预订违背
            contiuteBook(){
                this.$emit('contiuteBook',this.chooseReasonObj)
            },
			// 关闭弹框
			handleClose() {
		
				this.$emit('closeAlert',false)
			},
			// 返回查询界面
			goBackQueryPage(){
				this.$emit('goBackQueryPage',this.chooseReasonObj)
			},
			 
			
		}
	}
</script>

<style lang="scss" scoped >
	
	.vlt-main{
		
		.vlt-tit{
			text-align: left;
		}
		
		.vlt-con{
			margin: 20px 0;
			max-height: 200px;
			overflow-y: auto;
			border: 1px solid #ccc;
			padding: 20px;
			.vlt-w{
				width: 100%;
			}
			.vlt-line{
				line-height: 30px;
			}
			.vlt-obs{
				display: flex;
				justify-content: flex-start;
				.vlt-input-w{
					width: 40%;
				}
			}
		}
		.vlt-p{
			p{
				line-height: 28px;
			}
			.cl-red{
				color:red;
			}
		}
	}
	.vlt-tit ::v-deep{
		.el-dialog__body{
				padding: 10px 30px;
			}
	}
	.color-red{
		color:red;
	}
    .dialog-footer{
        display: block;
        width: 100%;
        text-align: center !important;
    }
</style>
